import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import APIs from '@services/APIs';
// eslint-disable-next-line import/no-unresolved
import classes from './Success.style.module.scss';

const Success = ({ successMessage, redirect = false }) => {
  document.body.style.background = '#fff';
  const { APP_LOGO } = process.env;
  const logo = localStorage.getItem(`${window.location.host}_app_logo`);
  const finalLogo = logo || `images/logos/${APP_LOGO}`;

  const handleRedirect = () => {
    if (redirect) {
      APIs.return();
    }
  };

  let successMessageContent = 'Thanks!';

  if (successMessage) {
    successMessageContent = parse(successMessage);
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes['img-container']} data-testid="cmplt-logo">
        <img alt="" src={finalLogo} />
      </div>
      <div
        className={classes.heading}
        data-testid="cmplt-heading"
        style={{ paddingBottom: '1.25rem !important' }}
      >
        {successMessageContent}
      </div>
      <div className={classes.title} onClick={handleRedirect} data-testid="cmplt-instruction">
        <h3 style={{ paddingBottom: '1.25rem', fontWeight: 'bold' }}>
          Your identity verification has been submitted.
        </h3>
        <p>What happens now?</p>
        <p>You may be contacted if there is further information required.</p>
        <p>You can close this window.</p>
      </div>
    </div>
  );
};
Success.propTypes = {
  successMessage: PropTypes.any,
  redirect: PropTypes.bool
};
export default Success;
