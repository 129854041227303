import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Page from '@lib/components/v2/Page';
import { Checkbox } from '@lib/components/v2/Form';
import { localizedString } from '@languages';

import classes from './Privacy.style.module.scss';

const Privacy = ({ onAccept, onSelectLanguage }) => {
  const [state, setState] = useState({ confirmed: false });
  const { backurl } = document.body.dataset;
  const buttons = [
    {
      label: localizedString('continue'),
      disabled: !state.confirmed,
      onClick: () => {
        if (state.confirmed) {
          onAccept();
        }
      },
      dataTestId: 'privacy-continue'
    }
  ];

  if (backurl) {
    buttons.unshift({
      label: localizedString('back'),
      variant: 'transparent',
      onClick: () => {
        window.location.href = backurl;
      }
    });
  }

  return (
    <Page
      title={localizedString('privacyTitle')}
      isPrivacy
      buttons={buttons}
      className={classes.wrapper}
      onSelectLanguage={onSelectLanguage}
    >
      <div className={classes.heading} data-testid="privacy-heading">
        Important information for you
      </div>
      <div className={classes.content}>
        <div className={classes.subheading}>
          ID verification - privacy collection notice + consent
        </div>
        <br />
        Australia and New Zealand Banking Group Limited ABN 11 005 357 522 (<b>ANZ/We/Our</b>) now
        offer a digital identity verification service so you don’t need to attend an ANZ branch to
        verify your identity if you have a compatible mobile device. This notice describes how ANZ
        collects and processes your personal information as part of its digital identity
        verification service.
        <br />
        <br />
        <div className={classes.subheading}>1. What ANZ collects</div>
        <ul>
          <li>
            Your name, mobile number, date of birth, address, email address, employment and
            citizenship (<b>General Information</b>);
          </li>
          <li>
            Images you submit on this site, including a video of your face (
            <b>Biometric Identification</b>);
          </li>
          <li>
            Clear images of your identity documents (<b>Documents</b>);
          </li>
          <li>
            Certain information relating to your device including network type and carrier name,
            device type and model, operating system, screen resolution and the location of your
            device using your IP address (<b>Device Information</b>); and
          </li>
          <li>
            Details of consents you’ve provided to ANZ relating to the collection and processing of
            your personal information.
          </li>
        </ul>
        Without this information, or if this information is inaccurate or incomplete, we may not be
        able to verify your identity via this service and/or provide you with the products or
        services you have requested.
        <br />
        <br />
        We may collect this information from you directly or through service providers who collect
        your personal information on our behalf.
        <br />
        <br />
        <div className={classes.subheading}>2. Why we process your personal information</div>
        <ul>
          <li>
            To verify your identity in order to provide you with the ANZ products or services you
            have requested; and
          </li>
          <li>
            To fulfil our legal obligations (such as fulfilling the requirements of the State based
            land titles office(s) and anti-money laundering and counter-terrorism financing laws)
            both before providing you with a product or service and on a periodic basis; and
          </li>
          <li>For customer due diligence, analytics and risk management purposes.</li>
        </ul>
        <div className={classes.subheading}>3. Who we share your personal information with</div>
        <br />
        We will disclose your personal information to our service provider, Equifax and their
        authorised subcontractor, OCR Labs (<b>Third Parties</b>) to match your Biometric
        Information with the Documents and Device Information. The Third Parties will verify your
        Documents using a Commonwealth Government Document Verification Service (<b>DVS Service</b>
        ). The DVS Service will validate the Documents by matching their content with existing
        information held by the issuer of the Document or the official record holder. This may be
        done using third party systems and services. The DVS Service will provide the Third Parties
        with confirmation of your identity verification status. The Third Parties will provide this
        confirmation to ANZ in a report which will include some or all of the Documents, Biometric
        Identification data and Device Information.
        <br />
        <br />
        We may also disclose your General Information to Equifax to confirm the existence (or
        non-existence) of your credit report. For the avoidance of doubt, ANZ will not access your
        credit report.
        <br />
        <br />
        If you have requested a home loan from ANZ then, where required, ANZ may disclose the fact
        that ANZ has verified your identity to the relevant State land title registries. ANZ will
        not share the actual information used in the verification.
        <br />
        <br />
        ANZ may otherwise disclose your General Information and/or the fact that ANZ has verified
        your identity to its related entities, alliance partners, agents, contractors and/or service
        providers that ANZ engages to carry out or assist its functions and activities.
        <br />
        <br />
        <div className={classes.subheading}>4. Our Privacy Policy</div>
        <br />
        ANZ’s Privacy Policy contains information about any laws that require or authorise ANZ to
        collect certain information from you, further details on when ANZ may collect your
        information from other sources (including from a third party), how to access the personal
        information ANZ hold about you, how you can seek to correct it and how you can raise
        concerns if you believe that ANZ has breached the Privacy Act or an applicable Code and how
        ANZ will deal with these matters.
        <br />
        <br />
        To contact us, you can visit{' '}
        <a target="_blank" rel="noopener noreferrer" href="http://www.anz.com.au/privacy/centre/">
          www.anz.com.au/privacy/centre/
        </a>
        , contact your banker or contact the ANZ call centre on 13 13 14
        <br />
        <br />
        <div className={classes.subheading}>Consent</div>
        <br />
        <Checkbox
          id="confirm"
          value={state.confirmed}
          onChange={(value) => setState({ confirmed: value })}
          label="By submitting this form, I consent to ANZ verifying my identity via the digital identity verification service and for ANZ  to  collect,  hold,  use  and disclose  my  personal  information  (including  any  sensitive  information)  for  the purposes set out above (as updated from time to time). I also represent and warrant to ANZ that I have the capacity and authority to give this consent and provide this personal information."
          dataTestId="privacy-chkBx"
          labelDataTestId="privacy-consentTxt"
        />
      </div>
    </Page>
  );
};

Privacy.propTypes = {
  onAccept: PropTypes.func,
  onSelectLanguage: PropTypes.func
};

export default Privacy;
